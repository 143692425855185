import React from 'react';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import Logo from '../../atoms/Logo/asset';

import * as styles from './Preview.module.css';

const Preview = ({values}) => {
    return (
        <table className={styles.GiftCertificate}>
            <tbody>
                <tr>
                    <td className={styles.GiftCertificateSpacerW}>&nbsp;</td>
                    <td className={styles.GiftCertificateBody}>
                        <div className={styles.StoreInfo}><Logo /></div>
                        <div className={styles.GiftCertificateToFrom}>
                            <div className={styles.GiftCertificateTo}>To: <span id="GiftCertificateTo">{values.recipients_name}</span></div>
                            <div className={styles.GiftCertificateFrom}>From: <span id="GiftCertificateFrom">{values.your_name}</span></div>
                        </div>
                        <div className={styles.GiftCertificateCode}>Code: <span id="GiftCertificateCode">XXX-XXX-XXX-XXX</span></div>
                        <div className={styles.GiftCertificateMessage}>{values.optional_message}</div>
                    </td>
                    <td className={styles.GiftCertificateDetails}>
                        <h1>
                            GIFT<br />
                            CERTIFICATE<br />
                            <span id="GiftCertificateAmount">
                                {values.amount ? (
                                    <CurrencyFormatter currency="AUD" amount={parseFloat(values.amount, 10)} appendZero={true} />
                                ) : (
                                    <>$0.00</>
                                )}
                            </span>
                        </h1>
                    </td>
                    <td className={styles.GiftCertificateSpacerW}>&nbsp;</td>
                </tr>
                <tr className={styles.GiftCertificateFooter}>
                    <td className={styles.GiftCertificateSpacerW}>&nbsp;</td>
                    <td className={styles.GiftCertificateShopNow} colspan="2">
                        <div className={styles.GiftCertificateExpiry}></div>
                        Redeem your gift certificate at <a href={process.env.SITE_URL}>{process.env.GENERAL_PROJECT_NAME}</a>
                    </td>
                    <td className={styles.GiftCertificateSpacerW}>&nbsp;</td>
                </tr>
            </tbody>
        </table>
    )
}

export default Preview