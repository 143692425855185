// extracted by mini-css-extract-plugin
export var GiftCertificate = "Preview-module--GiftCertificate--g-zDz";
export var GiftCertificateBody = "Preview-module--GiftCertificateBody--9f+lw";
export var GiftCertificateDetails = "Preview-module--GiftCertificateDetails--7tGLA";
export var GiftCertificateMessage = "Preview-module--GiftCertificateMessage--V7hCb";
export var GiftCertificateShopNow = "Preview-module--GiftCertificateShopNow--9WmuY";
export var GiftCertificateSpacerW = "Preview-module--GiftCertificateSpacerW--zF+kg";
export var GiftCertificateSpacerH = "Preview-module--GiftCertificateSpacerH--L9e0f";
export var StoreInfo = "Preview-module--StoreInfo--dlLM0";
export var GiftCertificateToFrom = "Preview-module--GiftCertificateToFrom--oKLro";
export var GiftCertificateFrom = "Preview-module--GiftCertificateFrom--2YvYM";
export var GiftCertificateTo = "Preview-module--GiftCertificateTo--Rzsem";
export var GiftCertificateCode = "Preview-module--GiftCertificateCode--wK8FQ";
export var GiftCertificateFooter = "Preview-module--GiftCertificateFooter--I5zve";
export var GiftCertificateExpiry = "Preview-module--GiftCertificateExpiry--VqCrS";